<template>
    <div>
        <div  class="mod-config" v-show="!deployBscreenVisible">
        <el-form :inline="true" :model="dataForm" @keyup.enter.native="search()">
            <table class="detailTable_search" style="width: 100%">
                <tr>
                    <td class="titleTd width80px">用户</td>
                    <td class="valueTd">
                      <el-input v-model="dataForm.userName" placeholder="用户账号或名称" clearable></el-input>
                    </td>
                    <td class="titleTd width80px">{{ $t('sysRes.role') }}</td>
                    <td class="valueTd">
                      <el-select v-model="dataForm.roleId" @change="search" filterable clearable :placeholder="$t('users.tips')" style="width:100%">
                        <el-option
                            v-for="item in roles"
                            :key="item.roleId"
                            :label="item.roleName"
                            :value="item.roleId">
                        </el-option>
                      </el-select>
                    </td>
                </tr>
              <tr>
                <td class="titleTd width80px">组织</td>
                <td class="valueTd">
                  <org-select v-model="dataForm.orgId"  placeholder="请选择组织" ></org-select>  
                </td> 
                <td class="titleTd width80px">部门</td>
                <td class="valueTd">
                  <dept-select v-model="dataForm.departmentId" :singleCheck="true" placeholder="请选择部门" ></dept-select>
                </td>
              </tr>
            </table>
        </el-form>

        <div class="wxts_msg_search">
            <div class="f_t">
                <el-button-group>
                  <el-button size="mini" type="danger" @click="test()">测试</el-button>
                    <el-button size="mini" type="success" @click="search()" :disabled="dataListLoading">{{ $t('query') }}</el-button>
                    <el-button size="mini" v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle()" :disabled="dataListLoading">{{ $t('add') }}</el-button>
                    <el-button size="mini" v-if="isAuth('sys:user:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0 || dataListLoading">{{ $t('deleteBatch') }}</el-button>
                </el-button-group>
            </div>
        </div>
        <el-table :data="dataList" border stripe v-loading="dataListLoading" @selection-change="selectionChangeHandle" style="width: 100%;">
          <el-table-column type="selection" header-align="center" align="center" width="50">
          </el-table-column>
          <el-table-column prop="userNo" header-align="center" align="center" width="120" :label="$t('users.userNo')" sortable>
          </el-table-column>
          <el-table-column prop="username" header-align="center" align="center" width="120" :label="$t('users.userName')" sortable>
          </el-table-column>
          <el-table-column prop="orgName" header-align="center" align="center" label="组织" sortable>
            <template slot-scope="scope">
              <span :title="scope.row.orgId" class="cell-hidden">
                {{
                  orgFormat(scope.row.orgId)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="departmentsName" header-align="center" align="center" label="部门" sortable>
            <template slot-scope="scope">
              <span :title="scope.row.departmentIds" class="cell-hidden">
                {{
                  deptFormat(scope.row.departmentIds)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="roleName" header-align="center" align="center" :label="$t('sysRes.role')" sortable>
          </el-table-column>
          <el-table-column prop="type" header-align="center" align="center" width="90" :label="$t('users.type')" sortable>
              <template slot-scope="scope">
                  <sysDict type="user_type" :value="scope.row.type"></sysDict>
              </template>
          </el-table-column> 
          <el-table-column prop="status" header-align="center" align="center" width="90" :label="$t('users.status')" sortable>
              <template slot-scope="scope">
                  <sysDict type="user_status" :value="scope.row.status"></sysDict>
              </template>
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" min-width="80" :label="$t('handle')">
              <template slot-scope="scope">
                  <el-button type="text" size="small" @click="deployBscreenHandle(scope.row)">{{ $t('sysRes.configScreen') }} </el-button>
                  <el-button v-if="isAuth('sys:user:update') && scope.row.userId != userId" type="text" class="update-button" size="small" @click="addOrUpdateHandle(scope.row.userId)">{{ $t('update') }}</el-button>
                  <el-button v-if="isAuth('sys:user:delete')  && scope.row.userId != userId" type="text" class="del-button" size="small" @click="deleteHandle(scope.row)">{{ $t('delete') }}</el-button>
                  <el-button v-if="isAuth('sys:user:resetPass')  && scope.row.userId != userId" type="text" size="small" @click="resetPassHandle(scope.row.userId,scope.row.username)">{{ $t('users.resetPwd') }}</el-button>
              </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount" layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <!-- 弹窗, 新增 / 修改 -->
        <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @destoryOrg="addOrUpdateVisible = false"></add-or-update>
        </div>
        <deployBscreen v-if="deployBscreenVisible" ref="deployBscreen" @refreshDataList="getDataList"></deployBscreen>
    </div>

</template>

<script>
import { treeDataTranslate } from "@/utils";
import { encryptDes } from "@/utils/des";
import AddOrUpdate from "./add-or-update";
import deployBscreen from "@/views/modules/hbi/project/deployBscreen";
import { getAllList as getAllOrgList } from '@/api/sys/sysorg.js'
import { getAllList as getAllDeptList } from '@/api/sys/sysdept.js'
import { validatorPassword } from '@/utils/validate'
import { getList, resetPass, deleteUser} from '@/api/sys/user'
import {selectList} from "@/api/sys/role";
import {actTest} from "@/api/dataflow/linkopUserAct"

export default {
    components: {
        AddOrUpdate,deployBscreen
    },
    data() {
        return {
          deployBscreenVisible: false,
            dataForm: {
              userName: "",
              userNo: "",
              departmentIds:[],
              departmentId:"",
              postId:'',
              roleId:''
            },
            postOptions:[],
            roles:[],
            orgOptions:[],
            deptOptions:[],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false
        };
    },
    activated() {
      //this.getDataType()
      this.getDataList(); 
    },
    mounted() {
      this.getRoles();
      this.getOrgOptions();
      this.getDeptOptions();
    },
    computed: {
        userId: {
            get() {
                return this.$store.state.user.id;
            }
        }
    },
    methods: {
      thisPage(row){
        let totalPage = Math.ceil((this.totalCount-row)/this.pageSize)
        let pageIndex = this.pageIndex > totalPage ? totalPage : this.pageIndex
        //pageIndex = this.currentPage < 1 ? 1 : pageIndex
        return pageIndex
      },

        // 配置大屏
        deployBscreenHandle(row) {
        this.deployBscreenVisible = true
        this.$nextTick(() => {
          this.$refs.deployBscreen.deployBscreen(row)
        })
      },
        
        search() {
            this.pageIndex = 1;
            this.getDataList();
        }, 
        deptFormat(param) {
          let arr = this.deptOptions;
          let deptIds = param.split(",")
          let deptNames = [];
          deptIds.forEach(element => {
            let found = false;
            debugger
            for (let i = 0; i < arr.length; i++) {
                if (element == arr[i].id) {
                  deptNames.push(arr[i].name);
                  found = true;
                } 
            }
            if(!found) {
              deptNames.push(element);
            }
          });  
          return deptNames.join(",");
        },
        orgFormat(param) {
          let arr = this.orgOptions;
          for (let i = 0; i < arr.length; i++) {
              if (param == arr[i].id) {
                  return arr[i].name;
              }

          }
          return param;
        },
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.deployBscreenVisible =false;
            // this.dataForm.departmentId = this.dataForm.departmentIds && this.dataForm.departmentIds.length > 0 ? this.dataForm.departmentIds[this.dataForm.departmentIds.length-1] : ''
            getList({
              page: this.pageIndex,
              limit: this.pageSize,
              userNo: this.dataForm.userName,
              username: this.dataForm.userName,
              orgId:this.dataForm.orgId,
              departmentId:this.dataForm.departmentId,
              postId:this.dataForm.postId,
              roleId:this.dataForm.roleId 
            }).then(({ data }) => {
                if (data && data.code === 0) {
                  this.dataList = data.data.list
                  this.totalCount = data.data.totalCount

                  if (data.data.page != this.pageIndex) {
                      this.pageIndex = data.data.page
                  }
                } else {
                  if (data.msg) {
                      this.$message.error(data.msg)
                  }
                  this.dataList = []
                  this.totalCount = 0
                  this.pageIndex = 1
                } 
            }).finally(() => {
                this.dataListLoading = false;
            }); 
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList(); 
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList(); 
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            }); 
        },
        //用户密码重置
        resetPassHandle(id, username) {
          this.$prompt(this.$t('users.newPwd') + `[${username}]`, this.$t('prompt.title'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              closeOnClickModal: false,
              closeOnHashChange: false,
              // inputPattern: /\S/,
              inputValidator: validatorPassword,
              inputErrorMessage: this.$t('users.inputErrorMessage')
            })
            .then(({ value }) => {
              const n_pass = encryptDes(value); // encrypt(value);
              resetPass({
                  userId: id,
                  newPass: n_pass
              }).then(({ data }) => {
                  if (data && data.code === 0) {
                      this.$message({
                          message: this.$t('prompt.success'),
                          type: "success",
                          duration: 1000
                      });
                  } else {
                      this.$message.error(data.msg);
                  }
              });
            })
            .catch(() => {});
        },
        // 删除
        deleteHandle(row) {
          var userIds = row ? [row.userId] :
              this.dataListSelections.map(item => {
                  return item.userId;
              });

          this.$confirm(this.$t('users.deleteMsg'), this.$t('prompt.title'), {
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
              type: "warning"
            }).then(() => {
              deleteUser(userIds).then(({ data }) => {
                if (data && data.code === 0) {
                  this.$message({
                    message: this.$t('prompt.success'),
                    type: "success",
                    duration: 1000
                  });
                  this.pageIndex = this.thisPage(userIds.length)
                  this.getDataList();
                } else {
                  this.$message.error(data.msg);
                }
              });
            })
            .catch(() => {});
        },
        /** 获取组织列表 */
        getOrgOptions(){
          getAllOrgList().then(({data}) => {
            if (data && data.code === 0) {
              this.orgOptions = data.data;
            }else{
              console.error(`[${data.code}]${data.msg}`);
            }
          }).catch((err) => {
            console.log(err);
            this.$message.error(err)
          }).finally(_ => { 
          }) 
        },
        /** 获取机构列表*/
        getDeptOptions(){
          getAllDeptList().then(({data}) => {
            if (data && data.code === 0) {
              this.deptOptions = data.data;
            }else{
              console.error(`[${data.code}]${data.msg}`);
            }
          }).catch((err) => {
            console.log(err);
            this.$message.error(err)
          }).finally(_ => { 
          }) 
        }, 
        /** 获取岗位列表 */
        getRoles(){
          selectList().then(({data}) => {
            if (data && data.code === 0) {
              this.roles = data.data
            }else{
              console.error(`[${data.code}]${data.msg}`);
            }
          }).catch((err) => {
            console.log(err);
            this.$message.error(err)
          }).finally(_ => {})
        },
      test() {
        var parms = "userActType=01;userId=aMjCRlRU38HlHfyNQR8vz;userCard=1849d2411f5148da8605bfb5f0bca8cd;userType=ott;sn=48c555c458a1487a9b0dd232c5aefcfb;mac=41546044698a4f6286dd07f835ba344c;openArea=1007ca000002;filmCode=65191;prdCode=51023;itemCCode=331;itemSCode=;filmStart=2023-02-28 00:01:00;filmDoTime=2023-02-28 00:01:00;filmLong=656\n"+
            "userActType=01;userId=sK4b8ZfahRziaIERHBTcy;userCard=5579b0d8b875411e8703b87078d40ed6;userType=5G;sn=a4ee8098edd7469a81d74ea45487cd60;mac=a037470465c843cb982c52a7f76af84c;openArea=1007ca000003;filmCode=76547;prdCode=13183;itemCCode=;itemSCode=1405;filmStart=2023-02-28 00:01:00;filmDoTime=2023-02-28 00:01:00;filmLong=578\n"+
            "userActType=01;userId=gnyAXCqNMNgZ9lGzjBEEE;userCard=ccb7957c08854fe884b490ecc6ff9f87;userType=单宽带;sn=d524a8173e4045f1958583100760288e;mac=18e638c327d94131a18cf5a9af924648;openArea=1209xy000001;filmCode=70997;prdCode=19713;itemCCode=1827;itemSCode=;filmStart=2023-02-28 00:01:00;filmDoTime=2023-02-28 00:01:00;filmLong=720\n"
        actTest(parms).then(({data}) => {

        })


      }

      },
};
</script>
